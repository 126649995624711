<template>
  <div class="card" style="min-height: calc(100vh - 180px)">
    <div class="bg-blue-light p-1">
      <TitleButton
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          title="Add Product Costing"
          @onClickCloseButton="navigateToListPage"
      />
      <div class="row gy-1">
        <div class="col-md-4">
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-between filter-label">
              <label for="colFormLabel" class="form-label">Customer Name: </label>
              <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                  <i class="fas fa-search"></i>
              </span>
            </div>

            <AsyncSelect
                placeholder="Select Customer"
                v-model="contactProfile"
                :api-service="fetchContactProfiles"
                :format-label="formatPatientLabel"
                class="flex-grow-1"
                :additional-query="{type: 'customer'}"
            />
          </div>
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-between filter-label">
              <label for="colFormLabel" class="form-label">Buyer Name: </label>
              <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                  <i class="fas fa-search"></i>
              </span>
            </div>

            <AsyncSelect
                placeholder="Select Buyer"
                v-model="buyerProfile"
                :api-service="fetchContactProfiles"
                :format-label="formatPatientLabel"
                class="flex-grow-1"
                :additional-query="{type: 'buyer'}"
            />
          </div>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="hidden col-md-4">
              <label for="colFormLabel" class="form-label">Sample Ref & Date: </label>
              <input
                  class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                  tabindex="1"
                  type="date"
                  placeholder="Sample Ref & Date"
                  v-model="formData.sample_ref_date"
              >
              <label for="colFormLabel" class="form-label">Pre-Costing Ref & Date: </label>
              <input
                  class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                  tabindex="1"
                  type="date"
                  placeholder="Pre-Costing Ref & Date"
                  v-model="formData.pre_costing_ref_date"
              >
              <label for="colFormLabel" class="form-label">Quotation Ref & Date: </label>
              <input
                  class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                  tabindex="1"
                  type="date"
                  placeholder="Quotation Ref & Date"
                  v-model="formData.quotation_ref_date"
              >
            </div>
            <div class="hidden col-md-4">
              <label for="colFormLabel" class="form-label">Order/PI No: </label>
              <input
                  class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                  tabindex="1"
                  type="text"
                  placeholder="Order/PI No"
                  v-model="formData.order_pi_no"
              >
              <label for="colFormLabel" class="form-label">Order Qty: </label>
              <input
                  class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                  tabindex="1"
                  type="text"
                  placeholder="Order Qty"
                  v-model="formData.order_qty"
              >
              <label for="colFormLabel" class="form-label">Rate: </label>
              <input
                  class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                  tabindex="1"
                  type="text"
                  placeholder="Rate"
                  v-model="formData.rate"
              >
              <label for="colFormLabel" class="form-label">Value: </label>
              <input
                  class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                  tabindex="1"
                  type="text"
                  placeholder="Value"
                  v-model="formData.value"
              >
            </div>
            <div class="col-md-4">
              <label for="colFormLabel" class="form-label">Product Costing Date: </label>
              <input
                  class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                  tabindex="1"
                  type="date"
                  placeholder="Product Costing Date"
                  v-model="formData.date"
              >


              <label class="form-label">Currency</label>
              <v-select :options="currencyOptions" v-model="formData.currency" label="name"
                        :reduce="option => option.code" placeholder="Select a Currency"></v-select>
            </div>
            <div class="col-md-4">
              <label for="colFormLabel" class="form-label">Product Costing Name: </label>
              <input
                  class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                  tabindex="1"
                  type="text"
                  required
                  placeholder="Product Costing Name"
                  v-model="formData.product_costing_name"
              >
              <label for="colFormLabel" class="form-label">Total Mnf Cost: </label>
              <input
                  class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                  tabindex="1"
                  type="text"
                  placeholder="Costing Cost"
                  readonly
                  :value="getTotalCost"
              >
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="p-2">
      <ProductionComponentTable
          :total-cost="getTotalCost"
          type="production"
          tableTitle="Production"
          :tableItems="formData.productions"
      />
      <MaterialComponentTable
          type="raw_materials"
          tableTitle="A. Raw materials"
          :tableItems="formData.raw_materials"
      />
      <MaterialComponentTable
          type="packing_materials"
          tableTitle="B. Packing materials"
          :tableItems="formData.packing_materials"
      />
      <DirectComponentTable
          type="direct_labor"
          tableTitle="C. Direct Labor"
          :tableItems="formData.direct_labors"
      />
      <div class="card border-1">
        <div class="table-container">
          <table width="100%" class="table create-billing table-width">
            <tr class="bg-light-success">
              <th class="p-50">Prime Cost (A+B+C)</th>
              <th class="text-center">{{ getPrimeCost }}</th>
            </tr>
          </table>
        </div>
      </div>
      <DirectComponentTable
          type="direct_overhead"
          tableTitle="D. Direct Overhead"
          :tableItems="formData.direct_overheads"
      />
      <div class="card border-1">
        <div class="table-container">
          <table width="100%" class="table create-billing table-width">
            <tr>
              <th class="p-50">Total manufacturing cost (A+B+C+D)</th>
              <th>{{ totalManufacturingCost }}</th>
            </tr>
          </table>
        </div>
      </div>
      <div class="card border-1">
        <div class="table-container">
          <table width="100%" class="table create-billing table-width">
            <tr class="bg-light-primary">
              <th>E. General & Admin Overheads</th>
              <th class="float-end">
                <input type="text" class="form-control-sm w-50 text-center" v-model="formData.general_admin_overheads"
                       id="">
              </th>
            </tr>
          </table>
        </div>
      </div>
      <div class="card border-1">
        <div class="table-container">
          <table width="100%" class="table create-billing table-width">
            <tr class="bg-light-success">
              <th class="p-50">Total Cost (A+B+C+D+E)</th>
              <td></td>
              <th>
                {{ totalCost }}
              </th>
            </tr>
          </table>
        </div>
      </div>
      <div class="card border-1">
        <div class="table-container">
          <table width="100%" class="table create-billing table-width table-bordered">
            <tr>
              <th>Markup %</th>
              <th><input type="text" class="form-control-sm w-25 text-center" v-model="formData.markup"/> %</th>
              <th>
                {{ getMarkupAmount }}
              </th>
            </tr>
            <tr class="bg-light-primary">
              <th class="p-50">Sales price without VAT</th>
              <td></td>
              <th>
                {{ getSalePriceWithoutVat }}
              </th>
            </tr>
            <tr>
              <th>VAT</th>
              <th><input type="text" v-model="formData.vat" class="form-control-sm w-25 text-center"/> %</th>
              <th>
                {{ getVatAmount }}
              </th>
            </tr>
            <tr class="bg-light-primary">
              <th class="p-50">Sales price including VAT</th>
              <td></td>
              <th>
                {{ salesPriceIncludingVat }}
              </th>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="pb-5 px-2">
      <div class="d-flex flex-wrap gap-1 gy-2 justify-content-center">
        <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)" class="btn btn-primary">
          Save
        </button>
        <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(false)"
                class="btn btn-primary">Save & New
        </button>
        <button :disabled="productLoader" @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
      </div>
    </div>
    <GlobalLoader/>
    <ContactDropdownFilter
        v-if="store.state.isModalOpenThree"
        @onSearchContact="onSearchContact"
        type="supplier"
        :companyRoles="companyRoles"
    />
  </div>
</template>

<script setup>
import {computed, inject, onMounted, ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useStore} from "vuex";
import useDate from "@/services/utils/day";
import handleContact from '@/services/modules/contact'
import TitleButton from '@/components/atom/TitleButton'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import ProductionComponentTable from '@/components/molecule/company/inventory/product-costing/ProductionComponentTable.vue'
import MaterialComponentTable from '@/components/molecule/company/inventory/product-costing/MaterialComponentTable.vue'
import DirectComponentTable from '@/components/molecule/company/inventory/product-costing/DirectComponentTable.vue'
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import {generateTxnNumber} from "@/services/utils/voucherNumberGenerator";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handlePurchase from '@/services/modules/purchase'
import handleRole from "@/services/modules/role";
import handleInventory from '@/services/modules/inventory'
import handlePurchaseAndSales from "@/services/modules/purchase";

const date = useDate();
const router = useRouter()
const route = useRoute()
const store = useStore()

const showError = inject('showError');
const showSuccess = inject('showSuccess');

const {storeProductCosting} = handlePurchase()

const {fetchProductList} = handlePurchaseAndSales()
const {fetchContactProfiles} = handleContact()
const {formatPatientLabel} = useAsyncDropdownHelper();
const {fetchCompanyDefaultRoles} = handleRole();
const {fetchCountryOfOrigins} = handleInventory()

const companyId = computed(() => route.params.companyId)
let loader = ref(false)
let productLoader = ref(false)
let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let products = ref([])
let accountHeads = ref([])
let contacts = ref([])
let business = ref([])
let locations = ref([])
let accountPayable = ref([])
let prefix = ref('')
let serial = ref('')
const discountAmount = ref(0);
const product = ref(null);
const contactProfile = ref(null);
const buyerProfile = ref(null);
const companyRoles = ref([]);

let formData = ref({
  company_id: companyId.value,
  contact_profile_id: null,
  buyer_id: null,
  product_unit: null,
  status: 'active',
  type: 'general',
  sample_ref_date: '',
  pre_costing_ref_date: '',
  quotation_ref_date: '',
  order_pi_no: '',
  order_qty: '',
  rate: '',
  value: '',
  date: '',
  bill_number: '',
  unit: '',
  currency: '',
  pre_bom_quantity: null,
  costing_qty: 1,
  product_costing_name: '',
  bom_cost: '',
  total_sales_price: '',

  productions: [{
    id: null,
    product: null,
    product_costingable_id: null,
    product_costingable_type: 'App\\Models\\Product',
    quantity: 0,
    wastage: 100,
    rate: 0,
    amount: 0,
    type: 'production'
  }],
  raw_materials: [{
    for_total_cost: true,
    id: null,
    product: null,
    product_costingable_id: null,
    product_costingable_type: 'App\\Models\\Product',
    quantity: null,
    wastage: 0,
    wastage_quantity: 0,
    total_quantity: 0,
    rate: 0,
    amount: 0,
    type: 'raw_materials'
  }],
  packing_materials: [],
  direct_labors: [],
  direct_overheads: [],

  general_admin_overheads: 0,
  markup: 0,
  vat: 0,
})

watch(product, (newValue) => {
  if (newValue) {
    formData.value.product = {
      name: newValue.text,
      ...newValue
    }
    formData.value.product_id = newValue.id;
    formData.value.product_unit = newValue.description.unit.name;
  }
});

const countries = ref([])
const currencyOptions = computed(() =>
    Object.values(countries.value).map(currency => ({
      code: currency.currency,
      name: currency.currency,
    }))
);

const totalProductionPercentage = computed(()=> formData.value.productions.reduce((acc, item) => acc + item.wastage, 0));

const getPrimeCost = computed(() => {
  const totalRawMaterialAmount = formData.value.raw_materials.reduce((total, amount) => total + parseFloat(amount.amount), 0).toFixed(4);
  const totalPackingMaterialAmount = formData.value.packing_materials.reduce((total, amount) => total + parseFloat(amount.amount), 0).toFixed(4);
  const totalDirectLaborAmount = formData.value.direct_labors.reduce((total, amount) => total + parseFloat(amount.amount), 0).toFixed(4);

  return (parseFloat(totalRawMaterialAmount) + parseFloat(totalPackingMaterialAmount) + parseFloat(totalDirectLaborAmount)).toFixed(4);
})

const totalManufacturingCost = computed(() => {
  const totalDirectOverheadAmount = formData.value.direct_overheads.reduce((total, amount) => total + parseFloat(amount.amount), 0).toFixed(4);

  return (parseFloat(getPrimeCost.value) + parseFloat(totalDirectOverheadAmount)).toFixed(4);
})

const totalCost = computed(() => (parseFloat(totalManufacturingCost.value) + parseFloat(formData.value.general_admin_overheads)).toFixed(4))
const getMarkupAmount = computed(() => ((totalCost.value * formData.value.markup) / 100).toFixed(4))
const getSalePriceWithoutVat = computed(() => (parseFloat(getMarkupAmount.value) + parseFloat(totalCost.value)).toFixed(4))
const getVatAmount = computed(() => ((getSalePriceWithoutVat.value * formData.value.vat) / 100).toFixed(4))
const salesPriceIncludingVat = computed(() => (parseFloat(getSalePriceWithoutVat.value) + parseFloat(getVatAmount.value)).toFixed(4))

const getTotalCost = computed(() => {
  const totalRawMaterialAmount = formData.value.raw_materials.reduce((total, {for_total_cost, amount }) => for_total_cost ? total + +amount : total, 0).toFixed(4);
  const totalPackingMaterialAmount = formData.value.packing_materials.reduce((total, {for_total_cost, amount }) => for_total_cost ? total + +amount : total, 0).toFixed(4);
  const totalDirectLaborAmount = formData.value.direct_labors.reduce((total, {for_total_cost, amount }) => for_total_cost ? total + +amount : total, 0).toFixed(4);
  const totalDirectOverheadAmount = formData.value.direct_overheads.reduce((total, {for_total_cost, amount }) => for_total_cost ? total + +amount : total, 0).toFixed(4);

  return (parseFloat(totalRawMaterialAmount) + parseFloat(totalPackingMaterialAmount) + parseFloat(totalDirectLaborAmount) + parseFloat(totalDirectOverheadAmount)).toFixed(4);
})

onMounted(async () => {
  loader.value = true
  let voucher = await generateTxnNumber(`?company_id=${route.params.companyId}&voucher_type=journal_voucher&txn_type=product_costing`);
  prefix.value = voucher.prefix;
  serial.value = voucher.serial;
  formData.value.date = date.currentDate();
  const companyQuery = `?company_id=${companyId.value}`

  const getCountryOfOrigins = fetchCountryOfOrigins(companyQuery)
  Promise.all([
    getCountryOfOrigins.then(res => {
      countries.value = res.data
    }),
    fetchCompanyDefaultRoles(companyQuery).then(res => {
      if (res.data) companyRoles.value = res.data
    }),
  ]).then(() => loader.value = false).catch(() => loader.value = false)
})

watch(contactProfile, (newVal) => {
  if (!newVal) {
    return formData.value.contact_profile_id = null;
  }

  formData.value.contact_profile_id = newVal.id;
})

watch(buyerProfile, (newVal) => {
  if (!newVal) {
    return formData.value.buyer_id = null;
  }

  formData.value.buyer_id = newVal.id;
})

function navigateToListPage() {
  router.push({name: 'product-costing-list', params: route.params, query: route.query})
}

function onOpenContactSearchModal() {
  store.state.isModalOpenThree = true;
}

function onSearchContact(contact) {
  contactProfile.value = contact
}

function handleSubmit(redirect = false) {
  if (totalProductionPercentage.value !== 100){
    return showError('Total production percentage does not equal 100%. Please adjust the values.')
  }
  if (redirect) {
    saveButtonLoader.value = true
  } else {
    saveNewButtonLoader.value = true
  }
  formData.value.total_sales_price = salesPriceIncludingVat.value
  formData.value.bom_cost = getTotalCost.value
  formData.value.bill_number = prefix.value + '-' + serial.value
  if (!formData.value.date) formData.value.date = date.currentDate()

  storeProductCosting(formData.value).then(res => {
    saveButtonLoader.value = false
    saveNewButtonLoader.value = false
    if (!res.status) {
      return showError(res.message)
    }

    showSuccess(res.message)
    if (redirect) navigateToListPage()
    resetForm()
  }).catch(() => {
    saveButtonLoader.value = false
    saveNewButtonLoader.value = false
    productLoader.value = false
  })
}

// have to refactor
function resetForm() {

}

</script>
